var classname = document.getElementById("btnComprar");
var flag = 0;
var size = window.screen.width;

compruebaCA();
async function compruebaCA() {
    while(classname == null) {
        await timeoutCA(1000);
        await leerClaseCA();
    }
};

function timeoutCA(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function leerClaseCA() {
    classname = document.getElementById("btnComprar");
    if(classname != null) {
        console.log(classname);
        var btnComprar = document.getElementById("btnComprar");
        btnComprar.addEventListener("click",selectTypeQuestion);

        var btnPedido = document.getElementById("btnPedido");
        btnPedido.addEventListener("click",selectTypeQuestion);

        var btnDevolucion = document.getElementById("btnDevolucion");
        btnDevolucion.addEventListener("click",selectTypeQuestion);

        var btnFormaPago = document.getElementById("btnFormaPago");
        btnFormaPago.addEventListener("click",selectTypeQuestion);

        var btnCuenta = document.getElementById("btnCuenta");
        btnCuenta.addEventListener("click",selectTypeQuestion);

        var preguntas = Array.from(document.getElementsByClassName("preFrecuentesCentroAyuda"));
        
        preguntas.map((pregunta, index) => {
            pregunta.addEventListener('click', selectAnswer);
        });

        var btnVolver = Array.from(document.getElementsByClassName("contenedorVolver"));
        btnVolver.map((btn, index) => {
            btn.addEventListener('click', regresaPreguntas);
        });

        setLocationsLink();
        
        if(size <= 700) {
            let contenedor_preguntas = document.getElementsByClassName("areaPreguntas")[0];
            let contenedor_respuesta = document.getElementById("contenedor-respuestas");
    
            contenedor_preguntas.style.display = "none";
            contenedor_respuesta.style.display = "block";
        }

        window.addEventListener("resize", function () {
            size = window.screen.width;
            console.log(size);
            if(size <= 700) {
                console.log("empieza el desmadre");
            }
        });
    }
    return false;
};

const selectTypeQuestion = (e) => {
    e.preventDefault();
    let btn = e.currentTarget.id
    let old_selected = document.getElementsByClassName("buttonSelect")[0];
    let new_selected = document.getElementById(btn);
    if (btn == old_selected.id) {
        return;
    } else {
        old_selected.classList.remove("buttonSelect");
        new_selected?.classList.add("buttonSelect");
    }

    if (btn == "btnComprar") {
        let old_type_question = document.getElementsByClassName("show")[0];
        let new_type_question = document.getElementById("preguntasComoComprar");
        old_type_question.classList.add("hide");
        old_type_question.classList.remove("show");
        new_type_question?.classList.add("show");
        new_type_question?.classList.remove("hide");

        if(size > 700) {
            let old_question = document.getElementsByClassName("activo")[0];
            let default_active = document.querySelector('[data-pregunta="a1"]');
            let old_answer = document.getElementsByClassName("show-answer")[0];
            let default_answer = document.querySelector('[data-respuesta="a1"]');
            old_question.classList.remove("activo");
            default_active?.classList.add("activo");
            old_answer.classList.remove("show-answer");
            default_answer?.classList.add("show-answer");
        }
    }

    if (btn == "btnPedido") {
        let old_type_question = document.getElementsByClassName("show")[0];
        let new_type_question = document.getElementById("preguntasMiPedido");
        old_type_question.classList.add("hide");
        old_type_question.classList.remove("show");
        new_type_question?.classList.add("show");
        new_type_question?.classList.remove("hide");

        if(size > 700) {
            let old_question = document.getElementsByClassName("activo")[0];
            let default_active = document.querySelector('[data-pregunta="b1"]');
            let old_answer = document.getElementsByClassName("show-answer")[0];
            let default_answer = document.querySelector('[data-respuesta="b1"]');
            old_question.classList.remove("activo");
            default_active?.classList.add("activo");
            old_answer.classList.remove("show-answer");
            default_answer?.classList.add("show-answer");
        }
    }

    if (btn == "btnDevolucion") {
        let old_type_question = document.getElementsByClassName("show")[0];
        let new_type_question = document.getElementById("preguntasEnvio");
        old_type_question.classList.add("hide");
        old_type_question.classList.remove("show");
        new_type_question?.classList.add("show");
        new_type_question?.classList.remove("hide");

        if(size > 700) {
            let old_question = document.getElementsByClassName("activo")[0];
            let default_active = document.querySelector('[data-pregunta="c1"]');
            let old_answer = document.getElementsByClassName("show-answer")[0];
            let default_answer = document.querySelector('[data-respuesta="c1"]');
            old_question.classList.remove("activo");
            default_active?.classList.add("activo");
            old_answer.classList.remove("show-answer");
            default_answer?.classList.add("show-answer");
        }
        
    }

    if (btn == "btnFormaPago") {
        let old_type_question = document.getElementsByClassName("show")[0];
        let new_type_question = document.getElementById("preguntasFormasPago");
        old_type_question.classList.add("hide");
        old_type_question.classList.remove("show");
        new_type_question?.classList.add("show");
        new_type_question?.classList.remove("hide");

        if(size > 700) {
            let old_question = document.getElementsByClassName("activo")[0];
            let default_active = document.querySelector('[data-pregunta="d1"]');
            let old_answer = document.getElementsByClassName("show-answer")[0];
            let default_answer = document.querySelector('[data-respuesta="d1"]');
            old_question.classList.remove("activo");
            default_active?.classList.add("activo");
            old_answer.classList.remove("show-answer");
            default_answer?.classList.add("show-answer");
        }
    }
    if (btn == "btnCuenta") {
        let old_type_question = document.getElementsByClassName("show")[0];
        let new_type_question = document.getElementById("preguntasMiCuenta");
        old_type_question.classList.add("hide");
        old_type_question.classList.remove("show");
        new_type_question?.classList.add("show");
        new_type_question?.classList.remove("hide");

        if(size > 700 ) {
            let old_question = document.getElementsByClassName("activo")[0];
            let default_active = document.querySelector('[data-pregunta="e1"]');
            let old_answer = document.getElementsByClassName("show-answer")[0];
            let default_answer = document.querySelector('[data-respuesta="e1"]');
            old_question.classList.remove("activo");
            default_active?.classList.add("activo");
            old_answer.classList.remove("show-answer");
            default_answer?.classList.add("show-answer");
        }
    }
}


const selectAnswer = (e) => {
    e.preventDefault();
    if (flag == 0) {
        e.currentTarget.classList.add("activo");
        flag = 1;
    }
    
    let old_question = document.getElementsByClassName("activo")[0];
    let old_answer = document.getElementsByClassName("show-answer")[0];
    let question = e.currentTarget.getAttribute("data-pregunta");
    let answer = document.querySelector("[data-respuesta="+question+"]");

    old_question.classList.remove("activo");
    old_answer.classList.remove("show-answer");
    e.currentTarget.classList.add("activo");
    answer?.classList.add("show-answer");

    if(size < 700) {
        let contenedor_respuesta = document.getElementById("contenedor-respuestas");
        contenedor_respuesta.style.display = "block";

        let contenedor_preguntas = Array.from(document.getElementsByClassName("areaPreguntas"));
        contenedor_preguntas.map((item, index) => {
            item.style.display = "none";
        });
        
    }

}

function regresaPreguntas(){
    let contenedor_respuesta = document.getElementById("contenedor-respuestas");
    contenedor_respuesta.style.display = "none";
    let contenedor_preguntas = Array.from(document.getElementsByClassName("areaPreguntas"));
        contenedor_preguntas.map((item, index) => {
            item.style.display = "block";
        });
}

function setLocationsLink() {
    let location_content = document.getElementById("location_content");
    let btn_sucursales = document.getElementById("btn_sucursales");
    let tag_link = document.createElement("a");
    tag_link.href = btn_sucursales.getAttribute("data-link");
    tag_link.appendChild(btn_sucursales);
    location_content.appendChild(tag_link);
}