/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useHistory, Link, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import {
  CommerceEnvironment,
  KEY_CODES,
  SEARCHTERM,
} from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";

import styled from "styled-components";
//UI
import {
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { ClickAwayListener } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

//Category service
import categoryService from "../../../_foundation/apis/search/categories.service";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../../configs/catalog";
import axios from "axios";

const CustomButtonStyled = styled.button`
  color: white;
  background-color: #1bb0aa;
  display: inline-block;
  font-family: Roboto;
  font-weight: 300;
  line-height: 23px;
  overflow: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: auto;
  height: 44px;
  border-width: 0px;
  :hover {
    background-color: #ffb62a;
  }
  font-size: 2em !important;
  cursor: pointer;
`;

const CustomInputStyled = styled.input`
  position: absolute;
  width: 100%;
  left: 8em;
  padding: 0px;
  background-color: transparent;
  border: 0 none;
  outline: none;
  font-size: inherit;
  line-height: 18px;
  font-style: italic;
  font-weight: 300 !important;
  height: 44px;

  @media (min-width: 960px) and (max-width: 1279px) {
    .fix-placeholder {
      left: unset;
    }
  }
`;

const CustomDivStyled = styled.div`
  height: 100%;
  left: 20.5%;
  line-height: 44px;
  margin: 0;
  padding: 0 2.6%;
  position: block;
  width: 53%;
  min-width: 600px;
`;

const CustomFormStyled = styled.form`
  display: block;
  margin-top: 0em;
  background-color: #ffffff;
  height: 44px;
  padding-right: 50px;
  position: relative;
  text-align: right;
  vertical-align: middle;
  text-align: left;

  @media (min-width: 960px) and (max-width: 1279px) {
    width: 450px;
  }
`;

const CustomListHeaderStyled = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  background-color: #2e3047;
  display: inline-block;
  font-family: Roboto, sans-serif;
  height: 44px;
  left: 0;
  line-height: 12px;
  padding: 17px 12px 17px 14px;
  position: absolute;
  vertical-align: middle;
  width: 107px;
  color: white;
  text-align: left;
  cursor: pointer;
  :after {
    content: "";
    display: block;
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 0;
    height: 0;
    border-left: 7px solid white;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    transform: rotate(90deg);
  }
`;

const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
  @media (min-width: 768px) {
    .text {
      display: none;
    }
  }
`;

const DropDownListContainer = styled("div")`
  float: left;
  right: auto;
  left: 0px;
  width: 107px;
  border: 0px;
  display: block;
  position: absolute;
  top: 44px;
  z-index: 20;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
`;

const DropDownList = styled("ul")`
  margin: 0;
  padding: 0 16px 16px 16px;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const ListItem = styled("li")`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  list-style: none;
  color: rgb(0,0,0);
  cursor: pointer;
  display: block;
  margin: 0 -14px;
  border-top: 1px solid #c9cece;
  font-style: normal;
  padding: 16px 0px;
  :hover {
    text-decoration: underline;
  }
  :first-child {
    border-top: 0px solid #c9cece;
  }
  :last-child {
    padding: 16px 0px 0px;
  }
  font-style: italic;
  a:first-child {
    margin-left: 10px;
  }
`;

const styledMUI = makeStyles((theme) => ({
  customResults: {
    top: "auto !important",
    borderRadius: "0px 0px 6px 6px !important",
  },
  searchResponsive: {
    [theme.breakpoints.down(960)]: {
      minWidth: "auto !important",
      /*minWidth:"450px",*/
    },
  },
  listResponsive: {
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  inputResponsive: {
    width: "72.5% !important",
    [theme.breakpoints.down("md")]: {
      left: "0em !important",
    },
    [theme.breakpoints.up(960)]: {
      left: "8em !important",
    },
  },
  selectData: {
    background: "#f5f6f7",
  },
  select: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const SearchBar: React.FC<SearchBarProps> = ({
  showSearchBar,
  openSearchBar,
  closeSearchBar,
}) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<
    Array<Object>
  >([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<
    Array<Object>
  >([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<Object>>(
    []
  );
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  //const searchField = t("SearchBar.SearchField"); LAC
  const searchField = "Encuentra lo que estás buscando";
  //const keywordTitle = t("SearchBar.KeywordTitle");
  const keywordTitle = "PRODUCTOS SUGERIDOS";
  //const categoryTitle = t("SearchBar.CategoryTitle");
  const categoryTitle = "CATEGORIA";
  //const brandTitle = t("SearchBar.BrandTitle");
  const brandTitle = "MARCA";
  const [input, setInput] = React.useState("");
  const [inputData, setInputData] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);

  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(
    () => new Map()
  );

  const [inputDisabled, setinputDisabled] = React.useState(false);

  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const allCategories = {
    id: "allCategories",
    name: t("Todos"),
  };
  const categoryList = [allCategories, ...topCategories];

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setInputData(str);
    setShowSearchBar(!showSearchBar);
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    //setInput("");
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const payload = {
    ...payloadBase,
  };

  useEffect(() => {
    if (mySite) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        langId: -5,
        ...payload,
      };
      axios
        .get(
          `/search/resources/api/v2/categories?storeId=${storeID}&depthAndLimit=11%2C11&langId=-5`
        )
        .then((res) => {
          setTopCategories(res.data.contents);
        }).catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(() => {
    if (mySite) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand"],
        langId: -5,
        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data.suggestionView[0].entry;
            const brandsResponse = res?.data.suggestionView[1].entry;
            generateCategoriesList(categoriesResponse);
            generateCategoriesURL(categoriesResponse);
            generateBrandsList(brandsResponse);
            setinputDisabled(false);
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < categoriesResponse.length; i++) {
      lists.push(categoriesResponse[i].fullPath);
    }
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < brandsResponse.length; i++) {
      lists.push(brandsResponse[i].name);
    }
    setBrands(lists);
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      let url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;

    setInputData(element.value);
    setInput(element.value);
    retrieveSuggestions(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const contractId = mySite.contractId;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          langId: -5,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          ...payloadBase,
        };

        siteContentService
          .findKeywordSuggestionsByTermUsingGET(parameters)
          .then((res) => {
            if (res.status === OK) {
              const keywordSuggestions = res?.data.suggestionView[0].entry;
              if (keywordSuggestions) {
                let list: string[] = [];
                generateSuggestionList(keywordSuggestions, searchTerm, list);
                generateCatgoriesAndBrandsSuggestions(searchTerm, list);
                setNameList(list);
              }
            }
          });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const generateCatgoriesAndBrandsSuggestions = (
    userInput: string,
    listTemp: string[]
  ) => {
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => e.match(regex));
    let lists: object[] = [];
    if (matchedCategories) {
      for (let suggestion of matchedCategories) {
        if (lists.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = categoriesUrl.get(suggestion);
        nameListIndex++;
        lists.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setCategorySuggestions(lists);
    setShowCategories(true);
    const matchedBrands = brands?.filter((e) => e.match(regex));
    let lists2: object[] = [];
    if (matchedBrands) {
      for (let suggestion of matchedBrands) {
        if (lists2.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setBrandSuggestions(lists2);
    setShowBrands(true);
  };

  const generateSuggestionList = (
    keywordSuggestions: any[],
    userInput: string,
    listTemp: string[]
  ) => {
    const lists: object[] = [];

    listTemp.push(userInput);
    const listTemp2: string[] = [];

    for (let suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion.term;
        suggestionSkeleton.url =
          SEARCH + "?" + SEARCHTERM + "=" + suggestion.term;
        if (selectedId !== "allCategories" && selectedId !== null) {
          suggestionSkeleton.url += "&categoryId=" + selectedId;
        }
        listTemp.push(suggestion.term);
        lists.push(suggestionSkeleton);
        listTemp2.push(suggestion.term);
        nameListIndex++;
      }
    }
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    let len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
        setInputData(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
          setInputData(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
        setInputData(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            if (selectedId !== "allCategories" && selectedId !== null) {
              url += "&categoryId=" + selectedId;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
    setIsOpen(false);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const onOptionClicked = (name, id) => () => {
    setSelectedOption(name);
    setSelectedId(id);
    setIsOpen(false);
  };
  const submitSearchButton = () => {
    if (input.trim() !== "") {
      var buttonUrl = SEARCH + "?" + SEARCHTERM + "=" + input.trim();
      if (selectedId !== "allCategories" && selectedId !== null) {
        buttonUrl += "&categoryId=" + selectedId;
      }
      redirectTo(buttonUrl);
    }
  };
  const clickAwayCat = () => {
    setIsOpen(false);
  };

  const classes = styledMUI();
  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar>
        <CustomFormStyled onSubmit={submitSearch} noValidate>
          <CustomDivStyled className={classes.searchResponsive}>
            <DropDownContainer className={classes.listResponsive}>
              <CustomListHeaderStyled onClick={toggling}>
                {selectedOption || "Todos"}
              </CustomListHeaderStyled>
              {isOpen && (
                <DropDownListContainer>
                  <DropDownList>
                    {categoryList.map((option, i) => (
                      <ListItem
                        onClick={onOptionClicked(option.name, option.id)}
                        key={Math.random()}>
                        <a>{option.name}</a>
                      </ListItem>
                    ))}
                  </DropDownList>
                </DropDownListContainer>
              )}
            </DropDownContainer>
            <CustomInputStyled
              className={classes.inputResponsive + " fix-placeholder no-print"}
              placeholder={searchField}
              name="searchTerm"
              id="searchTerm"
              onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
              type="text"
              autoComplete="off"
              onKeyDown={onKeyDown}
              onClick={clickAwayCat}
              value={inputData}
            />
            <CustomButtonStyled
              type="button"
              onClick={() => {
                submitSearchButton();
              }}>
              <SearchIcon />
            </CustomButtonStyled>
          </CustomDivStyled>
        </CustomFormStyled>
        {(showKeywords || showCategories || showBrands) && (
          <ClickAwayListener
            onClickAway={() => {
              clearSuggestionsAndInputField();
            }}>
            <ul className={"searchBar-results " + classes.customResults}>
              {showKeywords && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className={
                      "searchBar-resultsCategory " + classes.selectData
                    }>
                    {keywordTitle}
                  </StyledMenuTypography>
                  {keywordSuggestions?.map((e: any, i: number) => (
                    <Link
                      key={`brand-${i}`}
                      to={e.url}
                      onClick={() =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={
                            e.arrIndex === index
                              ? "active" + classes.select
                              : classes.select
                          }
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}

              {showCategories && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className={
                      "searchBar-resultsCategory " + classes.selectData
                    }>
                    {categoryTitle}
                  </StyledMenuTypography>
                  {categorySuggestions?.map((e: any, i: number) => (
                    <Link
                      key={`category-${i}`}
                      to={e.url}
                      onClick={(evt) =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={
                            e.arrIndex === index
                              ? "active" + classes.select
                              : classes.select
                          }
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}

              {showBrands && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className={
                      "searchBar-resultsCategory " + classes.selectData
                    }>
                    {brandTitle}
                  </StyledMenuTypography>
                  {brandSuggestions?.map((e: any, i: number) => (
                    <Link
                      key={`brand-${i}`}
                      to={e.url}
                      onClick={(evt) =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={
                            e.arrIndex === index
                              ? "active" + classes.select
                              : classes.select
                          }
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}
            </ul>
          </ClickAwayListener>
        )}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
