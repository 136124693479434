/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/* eslint-disable */
//Standard libraries
import React, { useState } from "react";
import { Helmet } from "react-helmet";

//UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import imgDportenis from "./img/img12@2x.jpg";
import imgDpstreet from "./img/img11@2x.jpg";
import formasPago from "./img/formas-pago@2x.png";
import selloConfianza from "./img/sello_de_confianza@2x.jpg";
import segureTrust from "./img/segureTrust.png";

const CustomButtonBrands = styled.a`
  min-width: 210px;
  padding: 0 15px;
  height: 53px;
  line-height: 53px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  background-color: #005277;
  font-family: Roboto, sans-serif;
  font-size: 1.58em;
  font-weight: 600;
  color: white;

  &:hover {
    background-color: #004a6b;
  }
`;
const CustomBrandHeader = styled.h5`
  color: #2a2c32;
  font-family: Roboto, sans-serif;
  font-size: 19.88px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  text-transform: uppercase;
  display: block;
  ine-height: unset;
  margin: 0;
`;

const CustomInputEmail = styled.input`
  background: #fff none repeat scroll 0 0;
  border-radius: 0;
  box-sizing: border-box;
  color: black;
  float: left;
  height: 40px;
  border: none;
  padding: 0 6px;
  font-style: italic;
  border-bottom: 1px solid black;
  margin-left: 5%;
  margin-right: 10px;
  font-size: 16px !important;
`;
const CustomButtonMail = styled.a`
  background: black;
  border-left: none;
  color: white;
  display: inline-block;
  float: left;
  font-weight: bold;
  border: none;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  padding: 0 15px;
  width: auto;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 40px;
  vertical-align: middle;
  margin: 0 15;
`;

const CustomFooterHeaderBold = styled.span`
  /*font-size: 1.14em;*/
  font-size: 16px;
  color: #7e7e7e;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  display: block;

  @media (min-width: 1200px) {
    &.fixSize {
      margin-bottom: 35px;
    }
  }

  @media (max-width:960px) {
    &.fixSize {
      margin-top: -50px;
    }
  }
`;

const CustomFooterHeader = styled.span`
  color: #6e6e6e;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-size: 1em;
  font-weight: 400;
  display: inline-block;
  :hover {
    color: #009894;
  }
`;

const CustomFooterList = styled.ul`
  list-style: 0 none;
  margin: 13px 0 0;
  padding: 0;
  overflow: hidden;
  display: block;
  list-style: none;
  text-align: center;
`;

const CustomItemListLeft = styled.li`
  text-align: right;
  padding-left: 5px;
  padding-bottom: 10px;
  width: 50%;
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
`;

const CustomItemListRight = styled.li`
  text-align: left;
  padding-left: 5px;
  padding-bottom: 10px;
  width: 50%;
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
`;

const CustomImgSeal = styled.img`
  display: block;
  width: 25%;
  margin: 20px;
  margin-left: 37.5%;
`;

const CustomFooterCopyright = styled.span`
  text-align: center;
  padding-bottom: 20px;
  color: #989898;
  font-size: 0.98em;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  display: inline-block;
  min-width: max-content;

  @media (min-width: 320px) and (max-width: 600px) {
    &.copyYear {
      display: unset;
    }
  }
`;

const CustomDivSocial = styled.div`
  background-color: #333;
  width: 30px;
  height: 30px;
  padding-right: 14px;
  padding-top: 7px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
  transition: all ease-in-out 0.1s;
  &:hover {
    background-color: #040404;
  }
`;

const CustomContainerFooter = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
`;

const CustomDivContainerNewsLetter = styled.div`
  form {
    display: flex;
  }

  .bricks-form__submit {
    width: auto !important;
  }

  .bricks-form__fieldset {
    width: 100% !important;
  }

  .bricks-form__label {
    display: none !important;
  }

  .js-field-email {
    background: #fff none repeat scroll 0 0 !important;
    border-radius: 0 !important;
    box-sizing: border-box !important;
    color: black !important;
    float: left !important;
    height: 40px !important;
    border: none !important;
    padding: 0 6px !important;
    font-style: italic !important;
    border-bottom: 1px solid black !important;
    margin-left: 5% !important;
    margin-right: 10px !important;
    font-family: Roboto, "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
    font-size: 14px !important;
    width: 85% !important;
    font-weight: 100 !important;
  }

  .js-field-email:focus-visible {
    outline: 0px !important;
  }

  .bricks--component-button {
    min-width: 0 !important;
    background: black !important;
    border-left: none !important;
    border-radius: 0 !important;
    color: white !important;
    display: inline-block !important;
    float: left !important;
    font-weight: bold !important;
    border: none !important;
    height: 40px !important;
    box-sizing: border-box !important;
    text-align: center !important;
    text-decoration: none !important;
    padding: 0 15px !important;
    width: auto !important;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 14px !important;
    line-height: 40px !important;
    vertical-align: middle !important;
    margin: 0 15 !important;
    text-transform: uppercase !important;
    margin-top: 0px !important;
    transition: all ease-in-out .1s;
    &:hover {
      background-color: #393939 !important;
    }
  }

  .bricks-form {
    margin: 0px !important;
  }

  .bricks--component-text {
    display: none !important;
  }
`;

const styledMUI = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "25px",
    marginTop: "25px",
    textAlign: "center",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      borderBottom: "1px solid #e9e9e9",
    },
  },
  divItem: {
    marginTop: "30px",
  },
  divItemSop: {
    marginTop: "30px",
    minWidth: "320px",
  },
  divItemNews: {
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      minWidth: "350px",
    },
    [theme.breakpoints.down(1024)]: {
      display: "block",
    },
  },
  divResNews: {
    marginTop: "10px !important",
    textAlign: "center",
    width: "calc(80% - 70px)",
    marginLeft: "10%",
    justifyContent: "center",
    fontSize: "12px",
  },
  divResNewsErr: {
    textAlign: "left",
    width: "calc(80% - 81px)",
    marginLeft: "10%",
    fontSize: "12px",
    float: "left",
  },
  box: {
    backgroundColor: "#FFF",
    paddingTop: "0px !important",
  },
  divItemRes: {
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  ItemRes: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  ItemResShow: {
    textAlign: "center",
    marginBottom: "30px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconSocial: {
    color: "#FFF",
    position: "absolute",
  },
  copyRight: {
    "&:hover": {
      textDecoration: "",
      cursor: "pointer",
      color: "#009894",
    },
  },
  spanBrandRes: {
    [theme.breakpoints.down("md")]: {
      textAlign: "left !important",
      margin: "20px 20px 20px 20px",
    },
  },
  emailRes: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(84% - 70px) !important",
      marginLeft: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(90% - 70px) !important",
      marginLeft: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(80% - 70px) !important",
    },
  },
  emailValid: {
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
}));

function Footer(props: any) {
  const classes = styledMUI();

  const NewsLetterForm = ({ status = "", message = "", onValidated }) => {
    const [validEmailString, setValidEmailString] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let email;

    const submitNewsLetter = () => {
      if (email && regex.test(email.value)) {
        onValidated({
          EMAIL: email.value,
        });
      } else {
      }
    };
    return (
      <>
        <CustomInputEmail
          className={classes.emailRes}
          placeholder="Correo electrónico"
          ref={(node) => (email = node)}
          type="email"
          value={validEmailString}
          onChange={(event) => {
            setValidEmailString(event.target.value);
            if (regex.test(event.target.value)) {
              setValidEmail(true);
            } else {
              setValidEmail(false);
              console.log("saludos");
            }
          }}
        />
        <CustomButtonMail
          onClick={submitNewsLetter}
          className={validEmail ? classes.emailValid : ""}>
          ENVIAR
        </CustomButtonMail>
        {status === "sending" && (
          <div className={classes.divResNews}>Enviando...</div>
        )}
        {status === "error" && (
          <Grid container className={classes.divResNewsErr}>
            <span style={{ color: "red", fontSize: "14px" }}>
              El email ya estaba suscrito
            </span>
          </Grid>
        )}
        {status === "success" && (
          <Grid container className={classes.divResNews}>
            <span style={{ color: "green", fontSize: "14px" }}>{message}</span>
          </Grid>
        )}
      </>
    );
  };

  const LogoBar = () => {
    return (
      <>
        <Grid container className={classes.divItemRes}>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={3}></Grid>
          {/*<Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <a href="https://dpstreet.mx">
              <img
                src={imgDpstreet}
                alt="dpstreet"
                style={{ marginRight: "5%" }}
              />
            </a>
            <a href="https://dportenis.mx">
              <img src={imgDportenis} alt="dptenis" />
            </a>
          </Grid>*/}
        </Grid>
      </>
    );
  };
  const url =
    "https://wemarketingdigital.us3.list-manage.com/subscribe/post?u=7283268a64ede820775006fab&id=7332fa21a4";
  const FooterStyled = () => {
    return (
      <>
        <Grid container alignItems="center" className={classes.divContainer}>
          <Grid item xs={12} md={4} className={classes.divItemSop}>
            <CustomFooterHeaderBold className="fixSize">
              SOPORTE
            </CustomFooterHeaderBold>
            <CustomFooterList>
              <CustomItemListLeft>
                <a href="https://grupodportenis.com/">
                  <CustomFooterHeader>Nosotros</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/contactanos">
                  <CustomFooterHeader>Contáctanos</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/como-comprar">
                  <CustomFooterHeader>¿Cómo comprar?</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/formas-pagos">
                  <CustomFooterHeader>Formas de pago</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/entregas">
                  <CustomFooterHeader>Entregas</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/guia-tallas">
                  <CustomFooterHeader>Guía de tallas</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/cambios-devoluciones">
                  <CustomFooterHeader>
                    Cambios y devoluciones
                  </CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/aviso-privacidad">
                  <CustomFooterHeader>Aviso de privacidad</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/socio">
                  <CustomFooterHeader>Distribuidor DPDirecto</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/exito">
                  <CustomFooterHeader>Fórmula del éxito</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/ejecutivo">
                  <CustomFooterHeader>Ejecutivo de cuenta</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/centro-de-ayuda">
                  <CustomFooterHeader>Preguntas frecuentes</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/sitemap">
                  <CustomFooterHeader>Mapa del sitio</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight></CustomItemListRight>
            </CustomFooterList>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divItem}>
            <Grid item className={classes.ItemRes}>
              <CustomBrandHeader>PAGA COMO QUIERAS</CustomBrandHeader>
            </Grid>
            <Grid item className={classes.ItemResShow}>
              <CustomBrandHeader>¿TE PODEMOS AYUDAR?</CustomBrandHeader>
              <CustomButtonBrands href="tel:66 99 15 53 00">
                66 99 15 53 00
              </CustomButtonBrands>
            </Grid>
            <img src={formasPago} alt="Formas de pago" />
            <a
              href="https://sellosdeconfianza.org.mx/MuestraCertificado.php?NUMERO_SERIE=SC_b32"
              target="_blank">
              <CustomImgSeal src={selloConfianza/*"https://www.dpdirecto.mx/wcsstore/Dpcatalogo/images/sello_de_confianza.jpg"*/} alt="Sello de confianza"/>
            </a>
            <a
              href="https://sealserver.trustwave.com/cert.php?customerId=e6213078b56947a8aab3796d072dbc9f&size=105x54&style=invert"
              target="_blank">
              <CustomImgSeal src={segureTrust/*"https://sealserver.trustwave.com/seal_image.php?customerId=e6213078b56947a8aab3796d072dbc9f&size=105x54&style=invert"*/} alt="Certificado" />
            </a>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divItemRes}>
            <Grid item>
              <CustomFooterHeaderBold style={{ fontSize: "16px" }}>
                TIENDA
              </CustomFooterHeaderBold>
              <CustomItemListLeft>
                <a href="/hombre-dpdirecto">
                  <CustomFooterHeader>Hombre</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/mujer-dpdirecto">
                  <CustomFooterHeader>Mujer</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/nino-dpdirecto">
                  <CustomFooterHeader>Niño</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/marcas-dpdirecto">
                  <CustomFooterHeader>Marcas</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a href="/deportes-dpdirecto">
                  <CustomFooterHeader>Deportes</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="/ofertas-dpdirecto">
                  <CustomFooterHeader>Ofertas</CustomFooterHeader>
                </a>
              </CustomItemListRight>
            </Grid>
            <Grid item style={{ marginTop: "16px" }}>
              <CustomFooterHeaderBold style={{ fontSize: "16px" }}>
                GRUPO DP
              </CustomFooterHeaderBold>
              <CustomItemListLeft>
                <a href="https://dpstreet.mx" target="_blank" rel="noreferrer">
                  <CustomFooterHeader>dpstreet</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a href="https://dportenis.mx" target="_blank" rel="noreferrer">
                  <CustomFooterHeader>dportenis</CustomFooterHeader>
                </a>
              </CustomItemListRight>
              <CustomItemListLeft>
                <a
                  href="https://dpvale.com.mx"
                  target="_blank"
                  rel="noreferrer">
                  <CustomFooterHeader>dpvale</CustomFooterHeader>
                </a>
              </CustomItemListLeft>
              <CustomItemListRight>
                <a
                  href="https://clubdp.online/"
                  target="_blank"
                  rel="noreferrer">
                  <CustomFooterHeader>Club dp</CustomFooterHeader>
                </a>
              </CustomItemListRight>
            </Grid>
          </Grid>
          <LogoBar />
        </Grid>
      </>
    );
  };
  const NewsLetterSubscribe = () => {
    return (
      <>
        <Grid container alignItems="center" className={classes.divContainer}>
          <Grid item xs={12} md={3} lg={4} className={classes.divItem}>
            <CustomBrandHeader>LAS MEJORES MARCAS DE MODA</CustomBrandHeader>
            <CustomButtonBrands href="/marcas-dpdirecto">
              Ver marcas
            </CustomButtonBrands>
          </Grid>
          <Grid item xs={12} md={3} lg={4} className={classes.divItem}>
            <CustomBrandHeader>REDES SOCIALES</CustomBrandHeader>
            <a href="https://www.facebook.com/dpdirectomx/">
              <CustomDivSocial>
                <FaFacebookF size={15} className={classes.iconSocial} />
              </CustomDivSocial>
            </a>
            <a href="https://www.instagram.com/dpdirecto/">
              <CustomDivSocial>
                <ImInstagram size={15} className={classes.iconSocial} />
              </CustomDivSocial>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            className={classes.divItemNews}>
            <CustomBrandHeader className={classes.spanBrandRes}>
              RECIBE OFERTAS DESDE TU EMAIL
            </CustomBrandHeader>
            <CustomDivContainerNewsLetter
              role="main"
              id="newsletter-dpdirecto-1a2361dfca542089ab15"
            />
            <Helmet>
              <script
                type="text/javascript"
                src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js"></script>
              <script type="text/javascript">
                {`
               document.cookie = '_form_fields=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
               new RDStationForms('newsletter-dpdirecto-1a2361dfca542089ab15', 'UA-187174319-1').createForm();
               (function() {
                  setTimeout(function(){
                  var input = document.querySelectorAll('*[id^="rd-email_field"]');
                  input.forEach(element => document.getElementById(element.id).value = "");
                    }, 500);
                })();
              `}
              </script>
            </Helmet>
          </Grid>
        </Grid>
      </>
    );
  };

  const Copyright = () => {
    return (
      <Grid container alignItems="center" className={classes.divContainer} xs={12} md={12} lg={12}>
        <Grid item xs={4} md={4} lg={4}>
          <CustomFooterCopyright className="copyYear">
            ©2022 dpdirecto.mx. Todos los derechos reservados.
          </CustomFooterCopyright>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <CustomFooterCopyright></CustomFooterCopyright>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{ maxWidth: "85%"}} >
            <a href="/aviso-privacidad">
              <CustomFooterCopyright
                className={classes.copyRight + " copyYear"}>
                Aviso de Privacidad &gt;
              </CustomFooterCopyright>
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ maxWidth: "85%"}} >
            <a href="/terminos-condiciones">
              <CustomFooterCopyright
                className={classes.copyRight + " copyYear"}>
                Términos y condiciones &gt;
              </CustomFooterCopyright>
            </a>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box
        pt={6}
        className={`${classes.box} no-print`}
        style={{ maxWidth: "1280px", margin: "0 auto", padding: "0 10px" }}>
        <NewsLetterSubscribe />
        <FooterStyled />
        <Copyright />
      </Box>
    </>
  );
}

export default React.memo(Footer);
