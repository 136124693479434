/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Query Service
 * Query Service Api Documentation
 *
 * The version of the OpenAPI document: 9.1.6
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "./../../../../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./../../../../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./../../../../base";
// @ts-ignore
import { ProductDetail } from "./../../../../com/hcl/commerce/typescript/axios";
/**
 * V2ProductResourceApi - axios parameter creator
 * @export
 */
export const V2ProductResourceApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Gets Products
     * @param {string} storeId
     * @param {string} [categoryId]
     * @param {string} [searchTerm]
     * @param {Array<string>} [id]
     * @param {string} [productId]
     * @param {Array<string>} [partNumber]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [associationType]
     * @param {string} [attributeKeyword]
     * @param {string} [catalogId]
     * @param {string} [contractId]
     * @param {string} [currency]
     * @param {string} [langId]
     * @param {boolean} [checkEntitlement]
     * @param {string} [attachmentFilter]
     * @param {string} [profileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProducts: async (
      storeId: string,
      categoryId?: string,
      searchTerm?: string,
      id?: Array<string>,
      productId?: string,
      partNumber?: Array<string>,
      limit?: number,
      offset?: number,
      associationType?: string,
      attributeKeyword?: string,
      catalogId?: string,
      contractId?: string,
      currency?: string,
      langId?: string,
      checkEntitlement?: boolean,
      attachmentFilter?: string,
      profileName?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeId' is not null or undefined
      assertParamExists("findProducts", "storeId", storeId);
      const localVarPath = `/api/v2/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (storeId !== undefined) {
        localVarQueryParameter["storeId"] = storeId;
      }

      if (categoryId !== undefined) {
        localVarQueryParameter["categoryId"] = categoryId;
      }

      if (searchTerm !== undefined) {
        if (/^\d+[-]{1}\d+$/.test(searchTerm) || /^\d+[-]{1}\d+[-]{1}[T]\d+$/.test(searchTerm)) {
          localVarQueryParameter["partNumber"] = searchTerm;
        } else {
          localVarQueryParameter["searchTerm"] = searchTerm;
        }
      }

      if (id) {
        localVarQueryParameter["id"] = id;
      }

      if (productId !== undefined) {
        localVarQueryParameter["productId"] = productId;
      }

      if (partNumber) {
        localVarQueryParameter["partNumber"] = partNumber;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (associationType !== undefined) {
        localVarQueryParameter["associationType"] = associationType;
      }

      if (attributeKeyword !== undefined) {
        localVarQueryParameter["attributeKeyword"] = attributeKeyword;
      }

      if (catalogId !== undefined) {
        localVarQueryParameter["catalogId"] = catalogId;
      }

      if (contractId !== undefined) {
        localVarQueryParameter["contractId"] = contractId;
      }

      if (currency !== undefined) {
        localVarQueryParameter["currency"] = currency;
      }

      if (langId !== undefined) {
        localVarQueryParameter["langId"] = langId;
      }

      if (checkEntitlement !== undefined) {
        localVarQueryParameter["checkEntitlement"] = checkEntitlement;
      }

      if (attachmentFilter !== undefined) {
        localVarQueryParameter["attachmentFilter"] = attachmentFilter;
      }

      if (profileName !== undefined) {
        localVarQueryParameter["profileName"] = profileName;
      }
      localVarQueryParameter["searchSource"] = "E";

      ///Se agrega filtro por Mas nuevo
      if (localVarQueryParameter["searchTerm"] == undefined) {
        localVarQueryParameter["orderBy"] = "5";
      }else{
        localVarQueryParameter["orderBy"] = "1";
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V2ProductResourceApi - functional programming interface
 * @export
 */
export const V2ProductResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    V2ProductResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Gets Products
     * @param {string} storeId
     * @param {string} [categoryId]
     * @param {string} [searchTerm]
     * @param {Array<string>} [id]
     * @param {string} [productId]
     * @param {Array<string>} [partNumber]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [associationType]
     * @param {string} [attributeKeyword]
     * @param {string} [catalogId]
     * @param {string} [contractId]
     * @param {string} [currency]
     * @param {string} [langId]
     * @param {boolean} [checkEntitlement]
     * @param {string} [attachmentFilter]
     * @param {string} [profileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProducts(
      storeId: string,
      categoryId?: string,
      searchTerm?: string,
      id?: Array<string>,
      productId?: string,
      partNumber?: Array<string>,
      limit?: number,
      offset?: number,
      associationType?: string,
      attributeKeyword?: string,
      catalogId?: string,
      contractId?: string,
      currency?: string,
      langId?: string,
      checkEntitlement?: boolean,
      attachmentFilter?: string,
      profileName?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProducts(
        storeId,
        categoryId,
        searchTerm,
        id,
        productId,
        partNumber,
        limit,
        offset,
        associationType,
        attributeKeyword,
        catalogId,
        contractId,
        currency,
        langId,
        checkEntitlement,
        attachmentFilter,
        profileName,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * V2ProductResourceApi - factory interface
 * @export
 */
export const V2ProductResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = V2ProductResourceApiFp(configuration);
  return {
    /**
     *
     * @summary Gets Products
     * @param {string} storeId
     * @param {string} [categoryId]
     * @param {string} [searchTerm]
     * @param {Array<string>} [id]
     * @param {string} [productId]
     * @param {Array<string>} [partNumber]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [associationType]
     * @param {string} [attributeKeyword]
     * @param {string} [catalogId]
     * @param {string} [contractId]
     * @param {string} [currency]
     * @param {string} [langId]
     * @param {boolean} [checkEntitlement]
     * @param {string} [attachmentFilter]
     * @param {string} [profileName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProducts(
      storeId: string,
      categoryId?: string,
      searchTerm?: string,
      id?: Array<string>,
      productId?: string,
      partNumber?: Array<string>,
      limit?: number,
      offset?: number,
      associationType?: string,
      attributeKeyword?: string,
      catalogId?: string,
      contractId?: string,
      currency?: string,
      langId?: string,
      checkEntitlement?: boolean,
      attachmentFilter?: string,
      profileName?: string,
      options?: any
    ): AxiosPromise<ProductDetail> {
      return localVarFp
        .findProducts(
          storeId,
          categoryId,
          searchTerm,
          id,
          productId,
          partNumber,
          limit,
          offset,
          associationType,
          attributeKeyword,
          catalogId,
          contractId,
          currency,
          langId,
          checkEntitlement,
          attachmentFilter,
          profileName,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * V2ProductResourceApi - object-oriented interface
 * @export
 * @class V2ProductResourceApi
 * @extends {BaseAPI}
 */
export class V2ProductResourceApi extends BaseAPI {
  /**
   *
   * @summary Gets Products
   * @param {string} storeId
   * @param {string} [categoryId]
   * @param {string} [searchTerm]
   * @param {Array<string>} [id]
   * @param {string} [productId]
   * @param {Array<string>} [partNumber]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [associationType]
   * @param {string} [attributeKeyword]
   * @param {string} [catalogId]
   * @param {string} [contractId]
   * @param {string} [currency]
   * @param {string} [langId]
   * @param {boolean} [checkEntitlement]
   * @param {string} [attachmentFilter]
   * @param {string} [profileName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V2ProductResourceApi
   */
  public findProducts(
    storeId: string,
    categoryId?: string,
    searchTerm?: string,
    id?: Array<string>,
    productId?: string,
    partNumber?: Array<string>,
    limit?: number,
    offset?: number,
    associationType?: string,
    attributeKeyword?: string,
    catalogId?: string,
    contractId?: string,
    currency?: string,
    langId?: string,
    checkEntitlement?: boolean,
    attachmentFilter?: string,
    profileName?: string,
    options?: any
  ) {
    return V2ProductResourceApiFp(this.configuration)
      .findProducts(
        storeId,
        categoryId,
        searchTerm,
        id,
        productId,
        partNumber,
        limit,
        offset,
        associationType,
        attributeKeyword,
        catalogId,
        contractId,
        currency,
        langId,
        checkEntitlement,
        attachmentFilter,
        profileName,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
