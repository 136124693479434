/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { Link } from "react-router-dom";

//Foundation
import { useSite } from "../../_foundation/hooks/useSite";
//UI
import {
  StyledMenuItem,
  StyledMenuTypography,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "@hcl-commerce-store-sdk/react-component";
import ExpandMoreIcon from "@material-ui/icons/Add";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import ArrowBackIosIcon from "@material-ui/icons/ArrowForwardIos";
import RemoveIcon from "@material-ui/icons/Remove";

const MegaMenuLink = (props: any) => {
  const {
    name,
    link,
    page,
    level,
    activeMenuId,
    setActiveMenuId,
    activeParentMenuId,
    setActiveParentMenuId,
    closeMegaMenu,
    parentId,
    parentName,
    site,
  } = props;

  return (
    <>
      {page.children && page.children.length > 0 ? (
        <MegaMenuColumn
          page={page}
          activeMenuId={activeMenuId}
          setActiveMenuId={setActiveMenuId}
          activeParentMenuId={activeParentMenuId}
          setActiveParentMenuId={setActiveParentMenuId}
          closeMegaMenu={closeMegaMenu}
          level={level + 1}
          parentId={parentId}
        />
      ) : (
        <Link
          to={link}
          onClick={() => {
            //GA360
            if (site.enableGA) AsyncCall.sendNavigationClick(parentName, name);
            closeMegaMenu();
          }}>
          <StyledMenuItem role="menuitem">
            <StyledMenuTypography variant="body1">
              <span>{name}</span>
            </StyledMenuTypography>
          </StyledMenuItem>
        </Link>
      )}
    </>
  );
};

interface MegaMenuColumnProps {
  page: any;
  activeMenuId: number | undefined;
  setActiveMenuId: any;
  activeParentMenuId: number | undefined;
  setActiveParentMenuId: any;
  closeMegaMenu: any;
  level: number;
  parentId: number | undefined;
}

/**
 * MegaMenu component
 * displays top category links in desktop/mobile view
 * @param props
 */
const MegaMenuColumn: React.FC<MegaMenuColumnProps> = (props: any) => {
  const {
    page,
    activeMenuId,
    setActiveMenuId,
    activeParentMenuId,
    setActiveParentMenuId,
    closeMegaMenu,
    level,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("md"));
  const id = page.id;
  const { mySite } = useSite();
  const icon = useMediaQuery(theme.breakpoints.down("sm")) ? (
    <ExpandMoreIcon className="iconAddDepartamento" />
  ) : (
    ""
  );
  const icon2 = useMediaQuery(theme.breakpoints.down("sm")) ? (
    <RemoveIcon className="iconAddDepartamento" />
  ) : (
    ""
  );

  const [activeSubMenuId, setActiveSubMenuId] = useState<number | undefined>();
  const [activeParentSubMenuId, setActiveParentSubMenuId] = useState<number | undefined>();
  
  return (
    <StyledAccordion
      elevation={0}
      square={true}
      className="container-departamentos-opcion"
      expanded={
        isMobile
          ? level === 1 || activeMenuId === id
          : activeParentMenuId === id || activeMenuId === id
      }>
      <StyledAccordionSummary
        className={`level-${level} container-departamentos-opcion-li`}
        expandIcon={activeParentMenuId === id ? icon2 : icon}
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
        onClick={() => {
          setActiveMenuId(activeMenuId !== id ? id : null);
          setActiveParentMenuId(activeParentMenuId !== id ? id : null);
        }}>
        <StyledMenuItem>
          <StyledMenuTypography
            variant={level === 1 ? "overline" : "body2"}
            className="category-title"
            id={`megamenu_department_${page.id}`}
            title={page.name}>
            {page.name.toLowerCase()}
          </StyledMenuTypography>
        </StyledMenuItem>
      </StyledAccordionSummary>
      <StyledAccordionDetails className="childrenList" style={{ paddingRight: "0px" }}>
        <Link
          to={page.seo?.href}
          onClick={() => {
            //GA360
            if (mySite.enableGA) {
              AsyncCall.sendNavigationClick(
                { eventAction: "Main", eventLabel: page.name },
                {
                  enableUA: mySite.enableUA,
                  enableGA4: mySite.enableGA4,
                }
              );
            }
            closeMegaMenu();
          }}>
          <StyledMenuItem role="menuitem" className="algo">
            <StyledMenuTypography variant="body1">
              <span style={{ textTransform: "unset" }} className="text-white">
                Ver todos
                <ArrowBackIosIcon
                  style={{ right: "40px", position: "absolute", top: "10px" }}
                />
              </span>
            </StyledMenuTypography>
          </StyledMenuItem>
        </Link>
        {page.children &&
          page.children.length > 0 &&
          page.children.map(function (subpage: any, index: number) {
            return (
              <StyledAccordion
                elevation={0}
                square={true}
                className="container-departamentos-opcion"
                expanded={
                  isMobile
                    ? level === 1 || activeSubMenuId === subpage.id
                    : activeParentSubMenuId === subpage.id ||
                      activeSubMenuId === subpage.id
                }>
                {subpage.children && subpage.children.length > 0 ? (
                  <StyledAccordionSummary
                    onClick={() => {
                      setActiveSubMenuId(
                        activeSubMenuId !== subpage.id ? subpage.id : null
                      );
                      setActiveParentSubMenuId(
                        activeParentSubMenuId !== subpage.id ? subpage.id : null
                      );
                    }}
                    className={`level-${level} container-departamentos-opcion-li `}
                    expandIcon={
                      subpage.children && subpage.children.length > 0 ? (
                        activeParentSubMenuId === subpage.id ? (
                          <RemoveIcon className="iconAddDepartamento"/>
                        ) : (
                          <ExpandMoreIcon className="iconAddDepartamento"/>
                        )
                      ) : (
                        <ArrowBackIosIcon
                          style={{ right: "10px", position: "absolute" }}
                        />
                      )
                    }
                    aria-controls={`${id}bh-content`}
                    id={`${id}bh-header`}
                    style={{ paddingLeft: "0px", background: "#0a9f99" }}>
                    <StyledMenuItem role="menuitem" className="algo">
                      <StyledMenuTypography
                        variant={"overline"}
                        className="category-title"
                        id={`megamenu_department_${subpage.id}`}
                        title={subpage.name}>
                        {subpage.name}
                      </StyledMenuTypography>
                    </StyledMenuItem>
                  </StyledAccordionSummary>
                ) : (
                    <Link
                      to={subpage.seo?.href}
                      onClick={() => {
                        //GA360
                        if (mySite.enableGA) {
                          AsyncCall.sendNavigationClick(
                            { eventAction: "Main", eventLabel: page.name },
                            {
                              enableUA: mySite.enableUA,
                              enableGA4: mySite.enableGA4,
                            }
                          );
                        }
                        closeMegaMenu();
                      }}>
                      <StyledMenuItem role="menuitem" className="algo">
                        <StyledMenuTypography
                          variant="body1"
                          id={`megamenu_department_${subpage.id}`}>
                          <span
                            style={{ textTransform: "unset" }}
                            className="text-white">
                            {subpage.name}
                            <ArrowBackIosIcon
                              style={{
                                right: "40px",
                                position: "absolute",
                                top: "10px",
                              }}
                            />
                          </span>
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                )}
                {subpage.children && subpage.children.length > 0 ? (
                  subpage.children.map(function (
                    subpagechild: any,
                    index2: number
                  ) {
                    return (
                      <StyledAccordionDetails
                        className="childrenList"
                        style={{ padding: "0px 0px 0px"}}>
                        {
                          index2 == 0 &&
                          <Link
                            to={subpage.seo?.href}
                            onClick={() => {
                              //GA360
                              if (mySite.enableGA) {
                                AsyncCall.sendNavigationClick(
                                  { eventAction: "Main", eventLabel: page.name },
                                  {
                                    enableUA: mySite.enableUA,
                                    enableGA4: mySite.enableGA4,
                                  }
                                );
                              }
                              closeMegaMenu();
                            }}>
                            <StyledMenuItem role="menuitem" className="algo">
                              <StyledMenuTypography
                                variant="body1"
                                id={`megamenu_department_${page.id}`}
                                style={{ paddingLeft: "20px" }}>
                                <span
                                  style={{ textTransform: "unset" }}
                                  className="text-white">
                                  Ver todos
                                  <ArrowBackIosIcon
                                    style={{
                                      right: "40px",
                                      position: "absolute",
                                      top: "10px",
                                    }}
                                  />
                                </span>
                              </StyledMenuTypography>
                            </StyledMenuItem>
                          </Link>
                        }
                        <Link
                          to={subpagechild.seo?.href}
                          onClick={() => {
                            //GA360
                            if (mySite.enableGA) {
                              AsyncCall.sendNavigationClick(
                                { eventAction: "Main", eventLabel: page.name },
                                {
                                  enableUA: mySite.enableUA,
                                  enableGA4: mySite.enableGA4,
                                }
                              );
                            }
                            closeMegaMenu();
                          }}>
                          <StyledMenuItem role="menuitem" className="algo">
                            <StyledMenuTypography
                              variant="body1"
                              id={`megamenu_department_${subpagechild.id}`}
                              style={{ paddingLeft: "20px" }}>
                              <span
                                style={{ textTransform: "unset" }}
                                className="text-white">
                                {subpagechild.name}
                                <ArrowBackIosIcon
                                  style={{
                                    right: "40px",
                                    position: "absolute",
                                    top: "10px",
                                  }}
                                />
                              </span>
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </Link>
                      </StyledAccordionDetails>
                    );
                  })
                ) : (
                  <></>
                )}
              </StyledAccordion>
            );
          })}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default MegaMenuColumn;
