/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, { createContext, Dispatch, Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Foundation libraries
import { site } from "./_foundation/constants/site";
import { initAxios } from "./_foundation/axios/axiosConfig";
import { initSite, useSite } from "./_foundation/hooks/useSite";
import LoginGuard from "./_foundation/guard/LoginGuard";
import {
  storageSessionHandler,
  localStorageUtil,
} from "./_foundation/utils/storageUtil";
import { LOCALE } from "./_foundation/constants/common";
import storeService from "./_foundation/apis/transaction/store.service";
import { PRODUCTION } from "./_foundation/constants/common";


//Custom libraries
import { ROUTE_CONFIG } from "./configs/routes";
import {
  CommerceEnvironment,
  DISCOVER_FEATURE,
  EMPTY_STRING,
} from "./constants/common";
import { Header } from "./components/header";
import { Footer } from "./components/custom/styledFooter";
import { Extensions } from "./components/extensions";
import { useCSRForUser } from "./_foundation/hooks/useCSRForUser";
import SuccessMessageSnackbar from "./components/widgets/message-snackbar/SuccessMessageSnackbar";
import ErrorMessageSnackbar from "./components/widgets/message-snackbar/ErrorMessageSnackbar";
import { IFRAME_RESIZER } from "./_foundation/constants/csr";
//Redux
import { forUserIdSelector, loginStatusSelector } from "./redux/selectors/user";
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from "./redux/actions/user";
//UI
import { StyledWrapper } from "./components/StyledUI";
import {
  StyledGrid,
  StyledProgressPlaceholder,
} from "@hcl-commerce-store-sdk/react-component";
import "./App.scss";
//GA360
//UA
import GTMDLService from "./_foundation/gtm/ua/gtmDataLayer.service";
//GA4
import GA4GTMDLService from "./_foundation/gtm/ga4/gtmDataLayer.service";
import Portal from "@material-ui/core/Portal";

import TagManager from "react-gtm-module";

//Custom Styles

import "../src/components/static-pages/css/home-page.scss";
import "../src/components/static-pages/css/distribuitor.scss";
import "../src/components/static-pages/css/help-center.scss";
import "../src/components/static-pages/css/static-page.scss";

/*import "./App.min.scss";
import "../src/components/static-pages/css/home-page.min.scss";
import "../src/components/static-pages/css/distribuitor.min.scss";
import "../src/components/static-pages/css/help-center.min.scss";
import "../src/components/static-pages/css/static-page.min.scss";*/

//Custom Scripts
import "../src/components/static-pages/js/help-center";

/*PayPal Plus */
declare global {
  interface Window {
    PAYPAL: any;
    userAddresData: any;
    ppp: any;
    user: any;
    seoHref: any;
    dpvale: any;
    productsQuantities: any;
    productQI: any;
    resetPass:boolean;
    addres: any;
  }
}

/*Tageo */
const tagManagerArgs = {
  gtmId: "GTM-MTWJ3H6",
};

/*const tagManagerArgs2 = {
  gtagId: 'G-VF8V9SBWDN'
}*/

TagManager.initialize(tagManagerArgs);
//TagManager.initialize(tagManagerArgs2);

const ScrollToTop = () => {
  React.useEffect(() => {
    //scroll to top on path change.
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);
  return <></>;
};

export const loginContext = createContext(() => {});

const App: React.FC = (props: any) => {
  const widgetName = getDisplayName(App);
  const loggedIn = useSelector(loginStatusSelector);
  const forUserId = useSelector(forUserIdSelector);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite, storeDisplayName } = useSite();
  const { i18n } = useTranslation();
  const { receiveParentMessage } = useCSRForUser();
  const CancelToken = Axios.CancelToken;

  let cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const [discover, setDiscover] = React.useState<boolean>(false);

  const setTranslate = () => {
    /**
     * language preference priority
     * 1. user context, to be implemented with language toggle
     * 2. localStorage (saved for 30 days).
     * 3. store default language.
     */
    // TODO: language toggle, update user language, read language from userContext if it is registered user.
    if (mySite) {
      const locale =
        localStorageUtil.get(LOCALE)?.split("_").join("-") ||
        CommerceEnvironment.languageMap[mySite.defaultLanguageID]
          .split("_")
          .join("-");
      if (locale !== i18n.languages[0]) {
        i18n.changeLanguage(locale);
      }
    }
  };
  initAxios(dispatch);

  /**
   * Function to check Discover is enabled for store based on storeId
   *
   * @param storeID
   */
  const isDiscoverEnabled = (storeID: string) => {
    const payload = {
      storeId: storeID,
      ...payloadBase,
    };
    storeService
      .getStoreEnabledFeaturesList(payload)
      .then((res) => {
        if (res.data && res.data.resultList) {
          setDiscover(res.data.resultList.includes(DISCOVER_FEATURE));
        }
      })
      .catch((e) => {
        let error = e.toString();
        if (error.indexOf("401") > -1 && window.location.pathname !== process.env.REACT_APP_EXPIRED_SESSION){
          window.location.href = ""+process.env.REACT_APP_EXPIRED_SESSION;
        }
      });
  };

  React.useEffect(() => {
    if (mySite) {
      dispatch(INIT_STATE_FROM_STORAGE_ACTION({ ...payloadBase }));
      storageSessionHandler.triggerUserStorageListener(() =>
        dispatch(LISTEN_USER_FROM_STORAGE_ACTION({ ...payloadBase }))
      );
      setTranslate();
      isDiscoverEnabled(mySite.storeID);
      //GA360
      if (mySite.enableGA) {
        if (mySite.enableUA) {
          GTMDLService.initializeGTM(
            mySite.gtmID,
            mySite.gtmAuth,
            mySite.gtmPreview
          );
        }
        if (mySite.enableGA4 && !mySite.enableUA) {
          GA4GTMDLService.initializeGTM(
            mySite.gtmID,
            mySite.gtmAuth,
            mySite.gtmPreview
          );
        }
      }
    } else {
      initSite(site, dispatch);
    }
  }, [mySite, dispatch]);

  React.useEffect(() => {
    if (forUserId) {
      window[IFRAME_RESIZER] = {
        onMessage: receiveParentMessage,
      };
    } else {
      window[IFRAME_RESIZER] = undefined;
    }
  }, [forUserId]);
  
  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  const baseName = process.env.REACT_APP_ROUTER_BASENAME
    ? { basename: (process.env.REACT_APP_ROUTER_BASENAME == "Sapphire") ? "/" : process.env.REACT_APP_ROUTER_BASENAME }
    : {};

  // public url path for accessing discoverui.js file.
  const publicUrlPath = process.env.PUBLIC_URL
    ? process.env.PUBLIC_URL
    : EMPTY_STRING;

  const [openLoginModal, setOpenLoginModal] = useState(false);
  //open login modal from body components
  function handleModalLogin() {
    setOpenLoginModal(true);
  }
  
  const [pageLoaded, setPageLoaded] = useState(window.location.pathname);
  function hashHandler(){
    let oldHash = "";
    if(pageLoaded != "/") {
      oldHash = pageLoaded.split("/")[(pageLoaded.split("/").length) - 1];
      oldHash = (oldHash === "" || oldHash === "home") ? "/" : oldHash;
    } else {
      oldHash = pageLoaded;
    }
    setInterval(detect, 500);
      
    function detect() {
      if(oldHash != window.location.pathname) {
        oldHash = window.location.pathname;
        if(oldHash != "/") {
          let path = oldHash.split('/')[(oldHash.split("/").length) - 1];
          setPageLoaded(path);
        } else {
          setPageLoaded(window.location.pathname);
        }
      }
    };
  }

  React.useEffect(() => {
    hashHandler();
  },[pageLoaded])

  return (
    mySite && (
      <loginContext.Provider value={handleModalLogin}>
        <BrowserRouter {...baseName}>
          <StyledWrapper data-testid="app-wrapper">
            <Portal>
              <SuccessMessageSnackbar />
              <ErrorMessageSnackbar />
            </Portal>
            <StyledGrid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="stretch"
              className="full-viewport-height">
              <StyledGrid item xs={false} className="container-header">
                <Header
                  loggedIn={loggedIn}
                  callLoginFromAnotherComponent={openLoginModal}
                  setOpenLoginFromAnotherComponent={setOpenLoginModal}
                />
                <LoginGuard />
                <ScrollToTop />
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{`${storeDisplayName}`}</title>
                  {discover && (
                    <script
                      src={`${publicUrlPath}/discover/discoverui.js?q=${Date.now()}`}
                      type="text/javascript"
                      async
                    />
                  )}
                  {window[IFRAME_RESIZER] && (
                    <script
                      src="/iframeResizer.contentWindow.min.js"
                      type="text/javascript"
                      async
                    />
                  )}
                </Helmet>
              </StyledGrid>
              <StyledGrid item xs className="full-width">
                <Suspense
                  fallback={
                    <StyledProgressPlaceholder className="vertical-padding-20" />
                  }>
                  {renderRoutes(
                    mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C
                  )}
                </Suspense>
              </StyledGrid>
              <StyledGrid item xs style={{width:"100%"}}>
                <Footer />
              </StyledGrid>
              {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
            </StyledGrid>
          </StyledWrapper>
        </BrowserRouter>
        {(pageLoaded == "/")  ?
          <Helmet>
            <script type="text/javascript">
              {`
                var home = document.getElementById("promo_slider_desktop");
                var brand = document.getElementById("slider_brands_content");

                comprueba();
                
                async function comprueba() {
                  do {
                    await timeout(1000);
                    await leerContenedor();
                  } while ( home == null || brand == null);
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function leerContenedor() {
                  home = document.getElementById("promo_slider_desktop");
                  brand = document.getElementById("slider_brands_content");
                  if( home != null && brand != null ) {
                      setLinksDesktopPromotional();
                      setLinksMobilePromotional();
                      setLinksBrands();
                      createLinks();
                      createCatalogLinkHome();
                      createAppLinksHome();
                      addLinksCatalogStores();
                      createLinksBottomCards();
                  }
                  return false;
                };

                function setLinksDesktopPromotional() {
                  let promotionals = Array.from(document.getElementsByClassName("promo-slider"));
                  let psd = document.getElementById("promo_slider_desktop");
                  let div_swiper = document.createElement("div");
                  let div_wrapper = document.createElement("div");
                  let div_pagination = document.createElement("div");
  
                  psd.innerHTML = "";
                  div_swiper.className = "swiper mySwiper";
                  div_wrapper.id = "desktop_promotional_swiper_wrapper";
                  div_wrapper.className = "swiper-wrapper";
                  div_pagination.className = "swiper-pagination";
                  
                  promotionals.map((element, index) => {
                    element.style.display = "block";
                    let div_slider = document.createElement("div");
                    let img_link = document.createElement("a");
                    let url_a = element.getAttribute("data-url");
                    
                    div_slider.className = "swiper-slide";
                    img_link.href = url_a;
                    img_link.appendChild(element);
                    div_slider.appendChild(img_link);
                    div_wrapper.appendChild(div_slider);
                  });
  
                  div_swiper.appendChild(div_wrapper);
                  div_swiper.appendChild(div_pagination);
                  psd.appendChild(div_swiper);

                  sliderDesktopPromotional();
                }
                
                function setLinksMobilePromotional() {
                  let mobile_promotionals = Array.from(document.getElementsByClassName("promo-slider-mobile"));
                  let psm = document.getElementById("promo_slider_mobile");
                  let div_swiper_m = document.createElement("div");
                  let div_wrapper_m = document.createElement("div");
                  let div_pagination_m = document.createElement("div");
                  
                  psm.innerHTML = "";
                  div_swiper_m.className = "swiper promotional_mobile";
                  div_wrapper_m.id = "mobile_promotional_swiper_wrapper";
                  div_wrapper_m.className = "swiper-wrapper";
                  div_pagination_m.className = "swiper-pagination";
  
                  mobile_promotionals.map((element_m, index_m) => {
                    element_m.style.display = "block"
                    let div_slider_m = document.createElement("div");
                    let img_link_m = document.createElement("a");
                    let url_a_m = element_m.getAttribute("data-url");
  
                    div_slider_m.className = "swiper-slide";
                    img_link_m.href = url_a_m;
                    img_link_m.appendChild(element_m);
                    div_slider_m.appendChild(img_link_m);
                    div_wrapper_m.appendChild(div_slider_m);
                  });
  
                  div_swiper_m.appendChild(div_wrapper_m);
                  div_swiper_m.appendChild(div_pagination_m);
                  psm.appendChild(div_swiper_m);

                  sliderMobilePromotional();
                }

                function setLinksBrands() {
                  let brandsElements = Array.from(document.getElementsByClassName("img100"));
                  let bs = document.getElementById("slider_brands_content");
                  let div_swiper = document.createElement("div");
                  let div_wrapper = document.createElement("div");
                  
                  bs.innerHTML = "";
                  div_swiper.className = "swiper swiper-container-brands";
                  div_wrapper.id = "brands_swiper_wrapper";
                  div_wrapper.className = "swiper-wrapper";
                  
                  brandsElements.map((element, index) => {
                    let div_slider = document.createElement("div");
                    let brand_link = document.createElement("a");
                    let url_brand = element.getAttribute("data-url");
                    
                    div_slider.className = "swiper-slide";
                    brand_link.href = url_brand;
                    brand_link.appendChild(element);
                    div_slider.appendChild(brand_link);
                    div_wrapper.appendChild(div_slider);
                  });
                  
                  div_swiper.appendChild(div_wrapper);
                  bs.appendChild(div_swiper);

                  sliderBrands();
                }

                function sliderDesktopPromotional() {
                  var swiper = new Swiper(".mySwiper", {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      loop: true,
                      autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                      },
                      pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }
                  });
                }

                function sliderMobilePromotional() {
                  var swiper_mobile = new Swiper(".promotional_mobile", {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      loop: true,
                      autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                      },
                      pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                      },
                      navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }
                  });
                }

              function sliderBrands() {
                var swiper_brands = new Swiper('.swiper-container-brands', {
                  slidesPerView: 5,
                  spaceBetween: 10,
                  loop: true,
                  autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                  },
                  breakpoints: {
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 10
                    },
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    },
                    768: {
                      slidesPerView: 8,
                      spaceBetween: 10
                    },
                    1024: {
                      slidesPerView: 11,
                      spaceBetween: 10
                    }
                  }
                });
              }

              function createCatalogLinkHome() {
                  
                  let catalogLinks = Array.from(document.getElementsByClassName("btnImgGallery"));
                  let catalogContent = document.getElementsByClassName("cards-galery")[0];
                  catalogLinks.map((catalogLink, index) => {
                    let url = catalogLink.getAttribute("data-url");
                    let a_tag = document.createElement("a");
                    a_tag.href = url;
                    a_tag.appendChild(catalogLink);

                    let div_catalog = document.createElement("div");
                    div_catalog.className = "card-galery";
                    div_catalog.appendChild(a_tag);
                    catalogContent.appendChild(div_catalog);
                  })
              }

              function createAppLinksHome() {
                let div = document.getElementsByClassName("download-app-text")[0];
                let links = Array.from(document.getElementsByClassName("button-download-app"));

                links.map((element, index) => {
                    let url = element.getAttribute("data-url");
                    let link = document.createElement("a");
                    link.href = url;
                    link.appendChild(element);
                    div.appendChild(link);
                });
              }

              function addLinksCatalogStores() {
                let store_catalogs = Array.from(document.getElementsByClassName("img-catalog-stores"));
                let div_content = document.getElementById("store_catalog_content");
                /*div_content.innerHTML = "";*/

                store_catalogs.map((store_catalog, index) => {
                  let link_store = document.createElement("a");
                  link_store.href = store_catalog.getAttribute("data-url");
                  link_store.appendChild(store_catalog);

                  let div_store = document.createElement("div");
                  div_store.className = "catalog";
                  div_store.appendChild(link_store);
                  div_content.appendChild(div_store);
                });
              }

              function createLinks() {
                let links = Array.from(document.getElementsByClassName("btnlinkhome"));
                let elements = document.querySelectorAll(".btnlinkhome");
                links.map((element,index) => {
                  let idbtn = element.getAttribute("id");
                  let url = element.getAttribute("data-link");
                  let text = element.getAttribute("data-text");
                  let a_tag = document.createElement("a");
                  element.setAttribute('key',index);
                  if (idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == false) {
                    a_tag.addEventListener('click', openRegister);
                  } else {
                    a_tag.href = url;
                  }

                  if(idbtn != null && idbtn == "btn_home_p" && ${loggedIn} == true) {
                    a_tag.href = "/dashboard";
                    a_tag.text = "MI CUENTA";
                  } else {
                    a_tag.text = text;
                  }
                  a_tag.setAttribute('key',"btn-"+index);
                  element.appendChild(a_tag);
                }); 
              }

              function createLinksBottomCards() {
                let links = Array.from(document.getElementsByClassName("btn-distribuidor"));
                links.map((element,index) => {
                    let a_tag = document.createElement("a");
                    let idbtn = element.getAttribute("id");
                    let url = element.getAttribute("data-url");
                    let text = element.getAttribute("data-text");
                    
                    element.setAttribute('key',"btn-bottomcard-"+index);
                    if(idbtn == "btn_partner") {
                      if(${loggedIn} == false) {
                        a_tag.href= "javascript:void(0)"
                        a_tag.addEventListener('click', openRegister);
                      } else {
                        a_tag.href = "/dashboard";
                      }
                    } else {
                        a_tag.href = url;
                    }
                    a_tag.style.cursor = "pointer";
                    
                    if(${loggedIn == true} && idbtn == "btn_partner") {
                      a_tag.text = "MI CUENTA";
                    } else {
                      a_tag.text = text;
                    }
                    
                    a_tag.setAttribute('key',"btn-"+index);
                    element.appendChild(a_tag);
                });
              }

              function openRegister() {
                let btn_register = document.getElementById("btn_register");
                let btn_menu_mobile = document.getElementById("btn_menu_mobile");

                if(btn_register != null) {
                  btn_register.click();
                } else {
                  btn_menu_mobile.click();
                  let menu_mobile = document.getElementById("menu_mobile");
                  menu_mobile.style.display = "none";
                  let btn_register_mobile = document.getElementById("btn_register_mobile");
                  btn_register_mobile.click();
                }
              }
              `}
            </script>
          </Helmet>
          : pageLoaded == "distribuidor" ?
              <Helmet>
                <script type="text/javascript">
                  {`
                     var testimonios = document.getElementsByClassName("testimonialSlider");

                     compruebaDistribuidor();                
                      async function compruebaDistribuidor() {
                        do {
                          await timeout(1000);
                          await leerContenedorDistribuidor();
                        } while ( testimonios[0] == null);
                      };

                      function timeout(ms) {
                        return new Promise(resolve => setTimeout(resolve, ms));
                      }

                      function leerContenedorDistribuidor() {
                        testimonios = document.getElementsByClassName("testimonialSlider");
                        
                        if(testimonios[0] != null) {
                            createLinks();
                            createAppLinks();
                            addFormScript();
                            loadSwiperClients();
                        }
                        return false;
                      };

                      function loadSwiperClients() {
                        var swiper_testimonial = new Swiper(".testimonialSlider", {
                          slidesPerView: 3,
                          spaceBetween: 5,
                          loop: true,
                          simulateTouch:false,
                          breakpoints: {
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 5
                            },
                            550: {
                              slidesPerView: 1,
                              spaceBetween: 5
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 5
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 5
                            },
                            1280: {
                              slidesPerView: 3,
                              spaceBetween: 5
                            },
                            1920: {
                              slidesPerView: 3,
                              spaceBetween: 5
                            }
                          },
                          navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                          },
                        });
                      }

                      function createLinks() {
                        if (document.getElementById("btn-distribuidor-0") == null) {
                          let links = document.getElementsByClassName("btn-distribuidor");
                          if(links.length == 0) {
                            createLinks();
                          }
                          Array.from(links).map((element,index) => {
                            element.setAttribute('key',"link-socio-"+index);
                            let a_tag = document.createElement("a");
                            let idbtn = element.getAttribute("id");
                            let url = element.getAttribute("data-url");
                            let text = element.getAttribute("data-text");
                              
                            if(idbtn == "btn_banner_distribuidor" || idbtn == "btn_como_ser_distribuidor" || idbtn == "btn_partner") {
                                if(${loggedIn} == false) {
                                  a_tag.addEventListener('click', openRegister);
                                } else {
                                  a_tag.href = "/dashboard";
                                }
                              } else if(idbtn == "btn_benefit_distribuidor") {
                                  a_tag.addEventListener('click', moveToDiv);
                              } else {
                                  a_tag.href = url;
                              }
                              a_tag.style.cursor = "pointer";
                              if(${loggedIn == true} && (idbtn == "btn_banner_distribuidor" || idbtn == "btn_como_ser_distribuidor" || idbtn == "btn_partner")) {
                                a_tag.text = "MI CUENTA";  
                              } else {
                                a_tag.text = text;
                              }
                              a_tag.setAttribute('id',"btn-distribuidor-"+index);
                              a_tag.setAttribute('key',"btn-"+index);
                              element.appendChild(a_tag);
                          });
                        }
                      }

                      function createLinksBottomCards() {
                        if (document.getElementById("btn-bottom-card-0") == null) {
                          let links = document.getElementsByClassName("btn-partner-content");

                          if(links.length <= 0) {
                            setTimeout(createLinksBottomCards(), 100);
                          }

                          Array.from(links).map((element,index) => {
                            element.setAttribute('key',"link-socio-"+index);
                            let linkButtom = document.createElement("a");
                            let idbtn = element.getAttribute("id");
                            let url = element.getAttribute("data-url");
                            let text = element.getAttribute("data-text");
                            
                            if(idbtn == "btn_partner") {
                              if(${loggedIn} == false) {
                                linkButtom.addEventListener('click', openRegister);
                                linkButtom.text = text;
                              } else if(${loggedIn} == true) {
                                linkButtom.text = "MI CUENTA";
                                linkButtom.href = "/dashboard";
                                  
                              }
                            } else {
                                linkButtom.text = text;
                                linkButtom.href = url;
                            }
                            linkButtom.style.cursor = "pointer";
                            linkButtom.setAttribute('id', "btn-bottom-card-"+index);
                            linkButtom.setAttribute('key',"btn-bottom-card-"+index);
                            element.appendChild(linkButtom);
                          });
                        }
                      }

                      function openRegister() {
                        let btn_register = document.getElementById("btn_register");
                        let btn_menu_mobile = document.getElementById("btn_menu_mobile");

                        if(btn_register != null) {
                          btn_register.click();
                        } else {
                          btn_menu_mobile.click();
                          let menu_mobile = document.getElementById("menu_mobile");
                          menu_mobile.style.display = "none";
                          let btn_register_mobile = document.getElementById("btn_register_mobile");
                          btn_register_mobile.click();

                        }
                    }
                    
                    function moveToDiv() {
                        let div = document.getElementById("be_a_distribuitor");
                        if( div != null ) {
                            div.scrollIntoView();
                        }       
                    }
                    
                    function createAppLinks() {
                        let div = document.getElementsByClassName("download-app-text")[0];
                        let links = Array.from(document.getElementsByClassName("button-download-app"));
                        links.map((element, index) => {
                            let link = document.createElement("a");
                            let url = element.getAttribute("data-url");
                            link.href = url;
                            link.appendChild(element)
                            div.appendChild(link);
                        });
                    }

                    function addFormScript() {
                      let form = document.getElementById("prueba-cc2a61b452b88269a64a");
                      if (form != null) {
                        new window.RDStationForms('prueba-cc2a61b452b88269a64a', 'UA-187174319-1').createForm();
                      } else {
                        console.log("error to load form");
                      }
                    }

                  `}
                </script>
              </Helmet>
          : pageLoaded == "centro-de-ayuda" ?
            <Helmet>
              <script type="text/javascript">
              {`
                var sp_hc = document.getElementById("centroAyuda");
                compruebaCentroDeAyuda();

                async function compruebaCentroDeAyuda() {
                  do {
                    await timeout(1000);
                    await leerContenedorCentroDeAyuda();
                  } while (sp_hc == null);
                };

                function leerContenedorCentroDeAyuda() {
                  sp_hc = document.getElementById("centroAyuda");
                  
                  if(sp_hc != null) {
                      loadSwiperHC();
                  }
                  return false;
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function loadSwiperHC() {
                  var swiper_hc = new Swiper(".help_center_swiper", {
                    slidesPerView: 5,
                    spaceBetween: 10,
                    breakpoints: {
                      320: {
                        slidesPerView: 1.5,
                        spaceBetween: 10
                      },
                      550: {
                        slidesPerView: 1.5,
                        spaceBetween: 10
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                      },
                      1024: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      },
                      1280: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      },
                      1920: {
                        slidesPerView: 5,
                        spaceBetween: 10
                      }
                    }
                  });
                }
              `}
              </script>
            </Helmet> 
            : pageLoaded == "contactanos" ? 
            <Helmet>
              <script type="text/javascript">
              {`
                var sp_contactanos = document.getElementById("sp_contactanos");
                compruebaContactanos();
                async function compruebaContactanos() {
                  do {
                    await timeout(1000);
                    await leerContenedorContactanos();
                  } while ( sp_contactanos == null);
                };

                function timeout(ms) {
                  return new Promise(resolve => setTimeout(resolve, ms));
                }

                function leerContenedorContactanos() {
                  sp_contactanos = document.getElementById("sp_contactanos");
                  
                  if(sp_contactanos != null) {
                      setPhone();
                      setEmail();
                  }
                  return false;
                };

                function setPhone() {
                  let phone = document.getElementsByClassName("contaco-tel")[0];
                  phone.innerHTML = "<a href='tel:" + phone.getAttribute("data-tel") + "'>" + phone.getAttribute("data-formated") + "</a>" + phone.getAttribute("data-text");
                }

                function setEmail() {
                  let email = document.getElementsByClassName("contacto-email")[0];
                  let link_email = document.createElement("a");
                  link_email.href="mailto:"+email.getAttribute("data-email");
                  link_email.text = email.getAttribute("data-email");
                  email.appendChild(link_email);
                }
              `}
              </script>
            </Helmet>
          : null
        }
      </loginContext.Provider>
    )
  );
};

export default App;
