/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
//Custom libraries
interface RegistrationB2BContext {
  cid: string;
  switchModalLoginReg: any;
}

const TextLogin = styled.span`
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  color: #000000;
`;

const SignUpButton = styled.button`
  background: #f1f1f1;
  color: #333;
  border-radius: 0;
  min-width: 230px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-family: Fjalla One, sans-serif;
  line-height: 40px;
  :disabled {
    background: #1bb0aa9e;
  }
  :enabled {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const TextSignUp = styled.span`
  font-size: 13px;
  font-weight: 300;
  color: #000;
  font-family: "Roboto";
  line-height: 1.3;
`;
const styledLoginModal = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "10px",
    marginBottom: "20px",
    marginTop: "25px",
  },
  divItem: {
    marginTop: "30px",
  },
  divSepBot: {
    paddingBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      borderBottom: "1px solid #e9e9e9",
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #e9e9e9",
      marginBottom: "10px",
      paddingTop: "15px",
    },
  },
  box: {
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  loginTextBox: {
    margin: "0px !important",
    width: "226px",
    height: "45px",
    borderRadius: "0px",
    border: "1px solid #bfbfbf",
    backgroud: "#FFFFFF !important",
  },
  divInput: {
    marginBottom: "30px",
  },
  divForgot: {
    marginTop: "10px",
  },
  "@global": {
    fieldset: {
      display: "none",
    },
  },
  labelRegistration: {
    marginBottom: "30px",
    lineHeight: "0.9",
  },
}));

/**
 * Registration links for B2B
 * displays links to B2B registration forms
 * @param props
 */
const RegistrationB2BLayout: React.FC<RegistrationB2BContext> = (
  props: any
) => {
  const { t } = useTranslation();
  const cid = props.cid;
  const classesLogin = styledLoginModal();
  const switchModalLoginReg = props.switchModalLoginReg;

  return (
    <>
      <Grid container className={classesLogin.divSepBot}>
        <TextLogin>¿No tiene una cuenta?</TextLogin>
      </Grid>
      <Grid className={classesLogin.labelRegistration}>
        <TextSignUp>
          Regístrate super rápido y recibe todos los beneficios de ser un
          usuario dpdirecto.
        </TextSignUp>
      </Grid>
      <SignUpButton onClick={switchModalLoginReg}>
        ME QUIERO REGISTRAR
      </SignUpButton>
    </>
  );
};

export { RegistrationB2BLayout };
