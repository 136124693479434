/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
//UI
import {
  StyledBox,
  StyledContainer,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import MenubarMenuItem from "./MenubarMenuItem";
import { makeStyles } from "@material-ui/core/styles";

const styleContainerMenu: CSSProperties = {
  background: "#2e3047",
  height: 44,
};
const styleContainer: CSSProperties = {
  height: 44,
  display: "flex",
  justifyContent: "space-between",
};

const styleBox: CSSProperties = {
  height: 44,
  alignItems: "center",
  float: "left",
};

const styledContainerLinks: CSSProperties = {
  float: "right",
  height: "100%",
  maxWidth: 370,
  width: "40%",
};

const styledDivLeft: CSSProperties = {
  float: "left",
  marginTop: "3.5%",
};

const styledDivRight: CSSProperties = {
  float: "right",
  marginTop: "3.5%",
};

const styledLinks: CSSProperties = {
  height: "100%",
  width: "100%",
  fontSize: 16,
  color: "white",
  fontWeight: 300,
  fontFamily: "sans-serif",
};

const styledIconHelp: CSSProperties = {
  verticalAlign: "middle",
};

interface ExpandedMenuProps {
  pages?: any;
}

const styled = makeStyles((theme) => ({
  txt: {
    float: "right",
    height: "100%",
    maxWidth: 370,
    width: "40%",
    "@media (max-width: 930px)": {
      display: "none",
    },
  }
}));

/**
 * ExpandedMenu component
 * expanded menu for desktop/tablet view
 * @param props
 */
const ExpandedMenu: React.FC<ExpandedMenuProps> = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const pages = props.pages ? props.pages : [];
  const classes = styled();
  const allCategories = {
    id: "allCategories",
    children: pages,
    name: t("AllCategoriesExpandedMenu.AllCategoriesLabel"),
  };
  const categories = [allCategories, ...pages];
  const selectMenuItem = (cid: string) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };

  React.useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);

  return (
    <StyledBox className="expanded-menu-container" style={styleContainerMenu}>
      <StyledContainer overflow="hidden" style={styleContainer}>
        <StyledBox
          style={styleBox}
          display="flex"
          direction="row"
          flexWrap="nowrap"
          justifyContent="flex-start"
          alignContent="flex-start">
          {categories.map((page: any, index: number) => {
            if (page.id !== "allCategories") {
              return (
                <MenubarMenuItem
                  key={page.id}
                  selectedMenuItem={selectedMenuItem}
                  selectMenuItem={selectMenuItem}
                  page={page}
                />
              );
            }
          })}
        </StyledBox>

        <div className={classes.txt}>
          <div style={styledDivLeft}>
            <Link
              style={styledLinks}
              to={"/distribuidor"}
            >
              ¿Quieres ser distribuidor?
            </Link>
          </div>
          <div style={styledDivRight}>
            <img
              alt="¿Necesitas ayuda?"
              style={styledIconHelp}
              src="https://dpjye2wk9gi5z.cloudfront.net/wcsstore/DportenisStorefrontAssetStore/Attachment/2020/centroayuda/support.svg"
            />
            <Link
              style={styledLinks}
              to={"/centro-de-ayuda"}
            >
              {" ¿Necesitas ayuda?"}
            </Link>
          </div>
        </div>
      </StyledContainer>
    </StyledBox>
  );
};

export default ExpandedMenu;
