import { SET_NOMBRE_COMPONENTE_SUCCESS } from './../../action-types/componente';
/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Redux
// import {  } from "../../actions/componente";
import * as ACTIONS from "../../action-types/componente";


export function* setNombreComponente(action: any) {

  console.log('actions', action.payload)
  const { nombre } = action.payload;
  
    try {
    //   yield put(INIT_COMPONENT_SUCCESS_ACTION({nombre: nombre})
      yield put({
        type: ACTIONS.SET_NOMBRE_COMPONENTE_SUCCESS,
        payload: action.payload,
      });
    } catch (error) {
      console.log('error', error)
    } 
}