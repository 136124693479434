/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
//Foundation libraries
import { useSite } from "../../../../_foundation/hooks/useSite";
//Custom libraries
import { SignInLayout } from "../widgets/SignInLayout";
import { RegistrationLayout } from "../../../widgets/registration";
import { RegistrationB2BLayout } from "../widgets/RegistrationB2BLayout";
import { StandardPageHero2BlocksLayout } from "../layouts/";
import { SectionContent } from "../../../../_foundation/constants/section-content-type";

import { makeStyles } from "@material-ui/core/styles";

const styledLogin = makeStyles((theme) => ({
  divContainer: {
    paddingBottom: "25px",
    marginTop: "25px",
  },
  divItem: {
    marginTop: "30px",
  },
  box: {
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  divLayout: {
    [theme.breakpoints.up("md")]: {
      flexBasis: "45%",
      maxWidth: "50%",
    },
  },
  divSeparator: {
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      marginLeft: "5px",
      marginRight: "5px",
      height: "300px",
      width: "1px",
      background: "#C7C7C7",
      float: "left",
      color: "#4d4d4d",
      lineHeight: "1.1em",
    },
  },
}));

//Custom libraries
interface headerInterface {
  switchModalLoginReg: any;
  handleCloseModalLogin: any;
}

const Sign: React.FC<headerInterface> = (props: any) => {
  const classesLogin = styledLogin();
  const { mySite } = useSite();
  const isB2B: boolean = mySite?.isB2B ? mySite.isB2B : false;
  const [showSignIn, setShowSignIn] = useState(true);
  const redirectRoute: string = props.location?.state?.redirectRoute
    ? props.location.state.redirectRoute
    : "";
  const switchModalLoginReg = props.switchModalLoginReg;
  const handleCloseModalLogin = props.handleCloseModalLogin;

  const toggleSignInRegisterpage = (flag: boolean) => {
    setShowSignIn(flag);
  };

  const sectionOne: SectionContent[] = [
    {
      key: "sign-in-registration-page-signin",
      CurrentComponent: () => {
        return (
          <SignInLayout
            cid="sign-in-registration-page-signin"
            redirectRoute={redirectRoute}
            showRegistrationPage={toggleSignInRegisterpage}
            handleCloseModalLogin={handleCloseModalLogin}
          />
        );
      },
    },
  ];

  const sectionTwo: SectionContent[] = [
    {
      key: "sign-in-registration-page-new-registration",
      CurrentComponent: () => {
        return (
          <>
            {isB2B ? (
              <RegistrationB2BLayout
                cid="sign-in-registration-page-new-registration"
                switchModalLoginReg={switchModalLoginReg}
              />
            ) : (
              <RegistrationLayout
                cid="sign-in-registration-page-new-registration"
                showSignInPage={toggleSignInRegisterpage}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <StandardPageHero2BlocksLayout
        cid="sign-in-registration-page"
        sectionOne={sectionOne}
        sectionTwo={sectionTwo}
        classesLogin={classesLogin}
      />
    </>
  );
};

export default Sign;
